import { useTheme } from "@emotion/react";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Box, /*,useTheme*/ Button, FormControl, InputLabel, MenuItem, TextField, useMediaQuery } from "@mui/material";
import Select from '@mui/material/Select';
import { useFormik } from "formik";
import { useState } from "react";
import swal from "sweetalert";
import useAuth from "../../../../auth/useAuth";
import { getAllStickersDataRoute, getStickerDataRoute, usersByRolRoute } from "../../../../config";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { tokens } from "../../../../theme";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";
//import ReceiptIcon from '@mui/icons-material/Receipt';
//import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { getDriverBoxesReport } from "../../../../config";
import { generateHTML, generateMultipleHTML } from "./HTMLGenerator/StikersGenerator";

const RoutesReport = ({permissionsListData}) => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [record, setRecord] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [errorRequest, setErrorRequest] = useState(null);
  const [showTable, setShowTable] = useState(false);
  //const navigate = useNavigate();

  const { data: driversListData, loading: driversListLoading, error: driversListError } = useFetchGET(usersByRolRoute(2), token, []);
  //console.log(driversListData)
  const [actionType, setActionType] = useState("");

  const getLocalDate = () => {
    return new Intl.DateTimeFormat('en-CA', { // 'en-CA' utiliza el formato YYYY-MM-DD
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date());
  };

  const today = getLocalDate()

  const customLocaleText = {
    ...esES.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: 'No se encontraron resultados...',
  };

  const getDetailBoxesXDriverReport = async (type, user_driver, init_date, end_date,driver_name="") => {
    if (user_driver && user_driver !== "") {
      try {
        setLoadingRequest(true)
        let res = await fetch(getDriverBoxesReport('json', user_driver, init_date, end_date), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          }
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log(data)
        if (res.ok) {
          if (type === 'html') {
            openPopupWithData(data, init_date, end_date, driver_name)
            //window.open(data.url, '_blank');
          } else {
            setRecord([...data.deliveries, ...data.pickups]);
            //setRecord([...data.pickups, ...data.deliveries]);
          }

        } else {
          //console.log('res', res)
          if (res.status === 404) {
            mostrarErrorRequest("Registro no encontrado")
            setRecord(null)
          }
          if (res.status === 401 || res.status === 403) {
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")
          }
        }
        setLoadingRequest(false)
        return data;
      } catch (errorRequest) {
        console.log('error de catch',errorRequest)
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const getStickersReport = async (user_driver, init_date, end_date) => {
    if (user_driver && user_driver !== "") {
      try {
        setLoadingRequest(true)
        let res = await fetch(getAllStickersDataRoute(user_driver, init_date, end_date), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          }
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log(data)
        if (res.ok) {
          if(data.length > 0){
            generateMultipleHTML(data)
          }else{
            mostrarAlertRequest('No se encontraron cajas asignadas a los drivers')
          }
        } else {
          if (res.status === 404) {
            mostrarErrorRequest("Not found")
          }
          if (res.status === 401 || res.status === 403) {
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")
          }
        }
        setLoadingRequest(false)
        return data;
      } catch (er) {
        setLoadingRequest(false)
        mostrarErrorRequest(er)
        //setErrorRequest(errorRequest)
      }
    }
  }

  function getDriverFullNameById(driverId, driversListData) {
    for (let i = 0; i < driversListData.length; i++) {
        if (driversListData[i].id === parseInt(driverId)) {
            return driversListData[i].name + " " + driversListData[i].lastname;
        }
    }
    return 'Conductor no encontrado';
}

  const rutasFormik = useFormik({
    initialValues: {
      initialDate: today,
      endDate: today,
      driver: '',
    },
    onSubmit: (values) => {
      //console.log(values)

      const driver_name = values.driver==="null"?'Todos':getDriverFullNameById(values.driver,driversListData)

      switch (actionType) {
        case "Listado":
          // Acción para 'Listado'
          getDetailBoxesXDriverReport('html', values.driver, values.initialDate, values.endDate,driver_name);
          //window.open(data,'_blank');
          break;
        case "Busqueda":
          // Acción para 'Busqueda'
          getDetailBoxesXDriverReport('json', values.driver, values.initialDate, values.endDate);
          setShowTable(true);
          break;
        case "Etiquetas":
          getStickersReport(values.driver, values.initialDate, values.endDate);
          break;
        /*case "Recivos Delivery":
          // Acción para 'Busqueda'
          console.log('Recivos Delivery')
          break;
        case "Recibos Pickup":
          // Acción para 'Busqueda'
          console.log('Recibos Pickup')
          break;*/
        default:
          console.log('Ninguna opcion seleccionada')
      }
    },
  });

  const now = new Date();
  const dateTimeString = now.toLocaleString();

  const openPopupWithData = (data,init_date,end_date,driver_name) => {
    const popupWindow = window.open('', '_blank', 'width=1200,height=800');
    const style = `
    <style>
      body {
        font-family: sans-serif;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      .report-title {
        font-size: 20px;
        font-weight: bold;
      }
      .user-info {
        text-align: right;
        font-size: 14px;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0;
      }
      th, td {
        border: 1px solid #ddd;
        padding: 5px;
        text-align: left;
      }
      th {
        background-color: #000000;
        color: #FFFFFF;
        text-align: center;
      }
      tr:nth-child(even) {
        background-color: #e0e5eb;
      }
      tr:hover {
        background-color: #CED3D8;
      }
    </style>
    `;
    popupWindow.document.write('<html><head><title>Detalles</title>');
    popupWindow.document.write(style); // Agregar los estilos
    popupWindow.document.write('</head><body>');
  
    // Agregar el encabezado
    popupWindow.document.write(`
    <div class="header">
      <div class="report-title">
        Reporte de Rutas <br>
        Del: ${init_date} al: ${end_date}<br>
        Driver: ${driver_name} <br>
      </div>
      <div class="user-info">
        Generado por: ${auth.globals.user} <br>
        ${dateTimeString}
      </div>
    </div>`);
  
    // Crear una sola tabla para deliveries y pickups
    popupWindow.document.write(generateSummationTable([...data.deliveries, ...data.pickups]));
    popupWindow.document.write(convertToCombinedTable(data.deliveries, data.pickups));
  
    popupWindow.document.write('</body></html>');
    popupWindow.document.close();
  }
  
  const convertToCombinedTable = (deliveries, pickups) => {
    //console.log(deliveries)
    //console.log(pickups)
    let table = "<table border='1'><tr>";
    if (deliveries.length > 0 || pickups.length > 0) {
      // Asumiendo que ambos vectores tienen las mismas claves
      let keys = deliveries.length > 0 ? Object.keys(deliveries[0]) : Object.keys(pickups[0]);
      const filteredKeys = keys.filter(key => key !== 'url_sticker' && key !== 'type');
      filteredKeys.forEach(key => {
        table += `<th>${getHeaderTitle(key)}</th>`;
      });
      table += "</tr>";
  
    // Agregar deliveries al HTML
    table += `<th colspan="${filteredKeys.length}">Deliveries (Total: ${deliveries.length})</th>`;
    deliveries.forEach(item => {
      table += "<tr>";
      filteredKeys.forEach(key => {
        table += `<td>${item[key]}</td>`;
      });
      table += "</tr>";
    });

    // Agregar pickups al HTML
    table += `<th colspan="${filteredKeys.length}">Pickups (Total: ${pickups.length})</th>`;
    pickups.forEach(item => {
      table += "<tr>";
      filteredKeys.forEach(key => {
        table += `<td>${item[key]}</td>`;
      });
      table += "</tr>";
    });
    }
    table += "</table>";
    return table;
  }

  const getHeaderTitle = (fieldName) => {
    switch (fieldName) {
      case 'id':
        return 'Id';
      case 'company':
        return 'Empresa';
      case 'senders_name':
        return 'Cliente';
      case 'senders_phone':
        return 'Teléfono';
      case 'senders_address':
        return 'Dirección';
      case 'senders_zip':
        return 'ZIP';
      case 'senders_state':
        return 'Estado';
      case 'senders_city':
        return 'Ciudad';
      case 'notes':
        return 'Notas';
      case 'size':
        return 'Tamaño';
      case 'total':
        return 'Total(USD)';
      case 'create_user':
        return 'Usuario Creador';
      case 'route_name':
        return 'Ruta';
      case 'user_driver':
        return 'Driver';
      case 'address_line2':
        return 'Dirección para etiqueta';
      default:
        return fieldName;
    }
  }

  function sumTotalsByCompany(allData) {
    const totalsByCompany = {};

    allData.forEach(item => {
        if (!totalsByCompany[item.company]) {
            totalsByCompany[item.company] = 0;
        }
        totalsByCompany[item.company] += item.total;
    });

    return totalsByCompany;
  } 

  function generateSummationTable(data) {
    const totalsByCompany = sumTotalsByCompany(data);
    let table = `<table border='1'><tr><th colspan="2">Totales por empresa</th></tr>`;
    table += "<tr><th>Empresa</th><th>Total</th></tr>";
    for (const company in totalsByCompany) {
        table += `<tr><td>${company}</td><td>${totalsByCompany[company]}</td></tr>`;
    }

    table += "</table>";
    return table;
  }

  const getStickerData = async(box_id) =>{
    if(box_id && box_id !== "" ){
      try{
        //console.log('box_id',box_id)
        let res = await fetch(getStickerDataRoute(box_id), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('respuesta sticker data',data)
        if(res.ok){
          //console.log('respuesta correcta')
          generateHTML(data,box_id)
        }else{
          if(res.status === 404){
            mostrarErrorRequest('No se encuentra la informacion para generar la etiqueta')
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
      }
    }
  }

  const limpiarCodigoDeBarras = (codigo) => {
    // Encuentra el índice del primer dígito numérico
    const indicePrimerDigito = codigo.search(/\d/);

    if (indicePrimerDigito === -1) {
        // No se encontraron dígitos, retorna un string vacío o el código original
        return '';
    }

    // Retorna la subcadena desde el primer dígito numérico hasta el final
    return codigo.substring(indicePrimerDigito);
  };
  
  const columns = [
    {
      field: "correlative",
      headerName: "ID",
      flex: 1,
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      minWidth: 80
    },
    {
      field: "company",
      headerName: "Empresa",
      flex: 1.75,
      cellClassName: "company-column--cell",
      minWidth: 135
    },
    {
      field: "senders_name",
      headerName: "Cliente",
      flex: 2,
      cellClassName: "senders_name-column--cell",
      minWidth: 140
    },
    {
      field: "senders_phone",
      headerName: "Teléfono",
      flex: 1,
      cellClassName: "senders_phone-column--cell",
      minWidth: 85,
      renderCell: (params) => (
        <a href={`tel:${params.value}`} style={{ textDecoration: 'none' }}>
          {params.value}
        </a>
      )
    },
    {
      field: "senders_address",
      headerName: "Dirección",
      flex: 4,
      cellClassName: "senders_address-column--cell",
      minWidth: 300
    },
    {
      field: "senders_zip",
      headerName: "Zip",
      flex: 0.5,
      cellClassName: "senders_zip-column--cell",
      minWidth: 60
    },
    {
      field: "senders_state",
      headerName: "Estado",
      flex: 0.75,
      cellClassName: "senders_state-column--cell",
      minWidth: 75
    },
    {
      field: "senders_city",
      headerName: "Ciudad",
      flex: 0.75,
      cellClassName: "senders_city-column--cell",
      minWidth: 110
    },
    {
      field: "notes",
      headerName: "Notas",
      flex: 2,
      cellClassName: "notes-column--cell",
      minWidth: 150
    },
    {
      field: "size",
      headerName: "Tamaño",
      flex: 0.75,
      cellClassName: "size-column--cell",
      minWidth: 75
    },
    {
      field: "total",
      headerName: "Total (USD)",
      flex: 0.5,
      cellClassName: "total-column--cell",
      minWidth: 50
    },
    {
      field: "user_driver",
      headerName: "Driver",
      flex: 1.75,
      cellClassName: "user_driver-column--cell",
      minWidth: 120
    },
    {
      field: "route_name",
      headerName: "Ruta",
      flex: 1,
      cellClassName: "route_name-column--cell",
      minWidth: 110
    },
    {
      field: "address_line2",
      headerName: "Dirección para etiqueta",
      flex: 1,
      cellClassName: "address_line2-column--cell",
      minWidth: 120
    },
    {
      flex:1,
      minWidth:110,
      filterable:false,
      sortable:false,
      field:"url_sticker", 
      headerName: "Documentos",
      headerAlign:"center",
      cellClassName: "url_sticker-column--cell",
      renderCell:({ row: { type, correlative} })=>{
        return(
          <Box
            width="95%"
            maxWidth="110px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto"
          >
            { type==='Pickup' && (
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{getStickerData(limpiarCodigoDeBarras(correlative))}}>
              <SaveAltIcon/>
              <Box sx={{ml:"5px"}}>
                Etiqueta
              </Box>
            </Button>)}
          </Box>
        )
      }
    },
  ]

  const rows = record.map((element, index) => ({
    id: index,
    correlative: element.id,
    senders_name:element.senders_name,
    senders_phone:element.senders_phone,
    senders_address:element.senders_address,
    senders_zip:element.senders_zip,
    senders_state:element.senders_state,
    senders_city:element.senders_city,
    notes:element.notes,
    size:element.size,
    company:element.company,
    total:element.total,
    create_user:element.create_user,
    user_driver:element.user_driver,
    route_name:element.route_name,
    url_sticker:element.url_sticker,
    type:element.type,
    address_line2:element.address_line2,
  }));


  // ---------------------------------------------------------- ALERTS ----------------------------------------------------------------------------
  const mostrarErrorRequest = (error) => {
    if (error != null) {
      try {
        swal({
          title: "Error",
          text: "Resultado: " + error,
          icon: "error",
          button: "Aceptar"
        })
        if (error === "No tiene autorización o su sesión ha expirado") {
          auth.logout();
        }
      } catch (e) {
        swal({
          title: "Error",
          text: "Error inesperado ",
          icon: "error",
          button: "Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }


/*
          <Button type="submit" variant="contained" color="secondary" onClick={() => setActionType("Recivos Delivery")} >
            <ReceiptIcon /> Recivos Delivery
          </Button>
          <Button type="submit" variant="contained" color="secondary" onClick={() => setActionType("Recibos Pickup")} >
            <ReceiptLongIcon /> Recibos Pickup
          </Button>
*/

  if (driversListLoading||loadingRequest)
    return (<Loading />)
  if (driversListError||errorRequest)
    return (<FatalError />)

  return (
    <Box>
      <form onSubmit={rutasFormik.handleSubmit}>
        <Box
          display="grid"
          gap="15px"
          marginBottom="15px"
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 12" }
          }}
        >
          <TextField
            label="Fecha inicial"
            name="initialDate"
            type="date"
            asp-format="{0:yyyy-MM-dd}"
            value={rutasFormik.values.initialDate}
            onChange={rutasFormik.handleChange}
            fullWidth
            variant="filled"
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ gridColumn: "span 6" }}
          />
          <TextField
            label="Fecha Final"
            name="endDate"
            type="date"
            asp-format="{0:yyyy-MM-dd}"
            value={rutasFormik.values.endDate}
            onChange={rutasFormik.handleChange}
            fullWidth
            variant="filled"
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ gridColumn: "span 6" }}
          />
          <FormControl variant="filled"
            disabled={false}
            sx={{ gridColumn: "span 6" }}
            required>
            <InputLabel id="select-driver-label" name="select-driver-label">Drivers</InputLabel>
            <Select
              labelId="select-driver-label"
              id="select-driver"
              fullWidth={!isNonMobile}
              //onBlur = {rutasFormik.handleBlur}
              value={rutasFormik.values.driver || ''}
              onChange={rutasFormik.handleChange}
              name="driver"
              size="small"
            >
              {permissionsListData.includes(40) && <MenuItem key={-1} value={'null'}>
                Todos
              </MenuItem>}
              {driversListData.map((driver) => (
                (permissionsListData.includes(40)?
                  <MenuItem key={driver.id} value={driver.id.toString()}>
                    {driver.name + ' ' + driver.lastname}
                  </MenuItem>
                  :driver.id === auth.globals.id?
                  <MenuItem key={driver.id} value={driver.id.toString()}>
                    {driver.name + ' ' + driver.lastname}
                  </MenuItem>
                :undefined)
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexDirection={isNonMobile ? 'row' : 'column'}
          justifyContent="flex-start"
          mt="10px"
          gap={1} // Añade un espacio entre los botones
        >
          <Button type="submit" variant="contained" color="secondary" onClick={() => setActionType("Listado")} >
            <FormatListBulletedIcon /> Listado (HTML)
          </Button>
          <Button type="submit" variant="contained" color="secondary" onClick={() => setActionType("Busqueda")} >
            <SearchIcon /> Busqueda
          </Button>
          <Button type="submit" variant="contained" color="secondary" onClick={() => setActionType("Etiquetas")} >
            <StickyNote2Icon /> Etiquetas
          </Button>

        </Box>
      </form>

      { showTable && (
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters": {
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          localeText={customLocaleText}
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
      )}

    </Box>
  );
}

export default RoutesReport;