import { InputAdornment, TextField } from "@mui/material";
import BadgeIcon from '@mui/icons-material/Badge';

const UserDataDelivery = ({ titleText, bodyText }) => {
    return (
        <TextField
            variant="filled"
            fullWidth={true}
            disabled={true}
            label={titleText}
            value={bodyText || "N/A" }
            size="small"
            sx={{ gridColumn: "span 6"}}
            slotProps={{ 
                input: {
                    readOnly: true
                }
            }}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <BadgeIcon/>
                  </InputAdornment>
                ),
              }}
            />
    )
}

export default UserDataDelivery;